const encodeQueryParams = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')

  return query === '' ? query : `?${query}`
}

// Default export omitted because this file is supposed to contain multiple utility-functions
// eslint-disable-next-line
export { encodeQueryParams }
